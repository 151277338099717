var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticStyle: {
      "background": "black"
    }
  }, [_vm.isAdminPanelRoute ? _c('v-app-bar', {
    attrs: {
      "app": "",
      "color": "black",
      "dark": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$store.commit('setDrawer', !_vm.$store.state.drawer);
      }
    }
  }), _vm.user && _vm.user.level > 0 ? _c('v-spacer') : _vm._e(), _vm.user && _vm.user.level > 0 ? _c('span', [_vm._v("Usuário: "), _c('strong', [_vm._v(_vm._s(_vm.user.name))])]) : _vm._e()], 1) : _vm._e(), _vm.isAdminPanelRoute ? _c('navigation-drawer') : _vm._e(), !_vm.isAdminPanelRoute && !_vm.isLoginRoute ? _c('navigation-drawer-user') : _vm._e(), _c('v-main', [_c('v-overlay', {
    attrs: {
      "value": _vm.loadingRoute
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-2"
  }, [_vm._v("Carregando")])])], 1), _c('router-view'), _c('notifications', {
    attrs: {
      "group": "app"
    }
  }), !_vm.isAdminPanelRoute ? _c('a', {
    staticClass: "whatsapp",
    style: `background-image: url(./../../imgs/whatsapp.png)`,
    attrs: {
      "href": "https://wa.me/5511917291001?text=Olá",
      "target": "_blank"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
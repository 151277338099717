<template>
  <v-app style="background: black">
    <v-app-bar v-if="isAdminPanelRoute" app color="black" dark>
      <v-app-bar-nav-icon
        @click.stop="$store.commit('setDrawer', !$store.state.drawer)"
      ></v-app-bar-nav-icon>
      <v-spacer v-if="user && user.level > 0"></v-spacer>
      <span v-if="user && user.level > 0"
        >Usuário: <strong>{{ user.name }}</strong></span
      >
    </v-app-bar>
    <navigation-drawer v-if="isAdminPanelRoute"></navigation-drawer>
    <navigation-drawer-user
      v-if="!isAdminPanelRoute && !isLoginRoute"
    ></navigation-drawer-user>
    <v-main>
      <v-overlay :value="loadingRoute">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-row class="ma-0" justify="center" align="center">
          <h5 class="mb-0 mt-2">Carregando</h5>
        </v-row>
      </v-overlay>
      <router-view></router-view>
      <notifications group="app" />
      <a
        v-if="!isAdminPanelRoute"
        href="https://wa.me/5511917291001?text=Olá"
        target="_blank"
        class="whatsapp"
        :style="`background-image: url(./../../imgs/whatsapp.png)`"
      ></a>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    NavigationDrawer: () => import("@components/NavigationDrawer"),
    NavigationDrawerUser: () => import("@components/NavigationDrawerUser"),
    HeaderComponent: () => import("@components/HeaderComponent.vue"),
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    user(v) {
      if (v && v.id && OneSignal) {
        OneSignal.setExternalUserId(`${v.id}`);
        OneSignal.showSlidedownPrompt();
      }
    },
  },
  computed: {
    route() {
      return this.$route?.path;
    },
    loadingRoute() {
      return this.$store?.state?.loadingRoute;
    },
    user() {
      return this.$store?.state?.user;
    },
    isAdminPanelRoute() {
      return (
        this.$route?.path.includes("/admin") ||
        this.$route?.path.includes("/photographer") ||
        this.$route?.path.includes("/seller")
      );
    },
    isLoginRoute() {
      return this.$route?.path.includes("/login");
    },
  },
  created() {
    window.addEventListener("resize", this.checkScreenType);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenType);
  },
  mounted() {
    this.checkScreenType();
    let self = this;
    window.OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
      OneSignal.init({
        appId: "53980cb8-1312-4740-b209-95add045a5ee",
      });
      OneSignal.on("notificationDisplay", function (e) {
        console.log("event onesignal", { e });
        self?.$store?.commit("setNotification", e);
        setTimeout(() => {
          self?.$store?.commit("setNotification", null);
        }, 5000);
      });
    });
  },
  methods: {
    checkScreenType() {
      if (window.mobileAndTabletCheck()) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
  },
};
</script>

<style scoped>
.whatsapp {
  background-size: contain;
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999999999;
}
</style>

import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

const routes = [];
routes.push({
  path: "*",
  component: () => import("./pages/Home.vue"),
  name: "Página Inicial",
});
routes.push({
  path: "/",
  component: () => import("./pages/Home.vue"),
  name: "Home",
});
routes.push({
  path: "/sac",
  component: () => import("./pages/Sac.vue"),
  name: "SAC",
});
routes.push({
  path: "/contribuir-com-vaquinha/:hash",
  component: () => import("./pages/Passthehat.vue"),
  name: "ContribuirVaquinha",
});
routes.push({
  path: "/contribuicao-finalizada/:id",
  component: () => import("./pages/FinishDonation.vue"),
  name: "ContribuicaoFinalizada",
});
routes.push({
  path: "/downloads",
  component: () => import("./pages/User/Downloads/Downloads.vue"),
  name: "Downloads",
});
routes.push({
  path: "/gallery",
  component: () => import("./pages/User/Gallery/Gallery.vue"),
  name: "Galeria",
});
routes.push({
  path: "/passthehats",
  component: () => import("./pages/User/Passthehats/Passthehats.vue"),
  name: "Vaquinha",
});
routes.push({
  path: "/passthehats/create",
  component: () => import("./pages/User/Passthehats/Create.vue"),
  name: "VaquinhaCreate",
});
routes.push({
  path: "/passthehats/show/:id",
  component: () => import("./pages/User/Passthehats/Show.vue"),
  name: "user.passthehats.show",
});
routes.push({
  path: "/loginforced/:token",
  component: () => import("./pages/Home.vue"),
  name: "Login como usuário",
});

routes.push({
  path: "/login",
  component: () => import("./pages/Login.vue"),
  name: "Login",
});
routes.push({
  path: "/dashboard",
  component: () => import("./pages/User/Dashboard.vue"),
  name: "Dashboard",
});
routes.push({
  path: "/profile",
  component: () => import("./pages/User/Profile.vue"),
  name: "Perfil",
});
routes.push({
  path: "/products",
  component: () => import("./pages/User/Products/List.vue"),
  name: "Produtos",
});
routes.push({
  path: "/purchases",
  component: () => import("./pages/User/Purchases/Purchases.vue"),
  name: "Minhas Compras",
});
routes.push({
  path: "/purchase/details/:id",
  component: () => import("./pages/User/Purchases/Details.vue"),
  name: "purchases.details",
  meta: {
    publicName: "Detalhes da Compra",
    levels: [0],
  },
});
routes.push({
  path: "/payment",
  component: () => import("./pages/User/Payment/Payment.vue"),
  name: "Pagamento",
});
routes.push({
  path: "/recognize-basis",
  component: () => import("./pages/User/Recognize/Basis.vue"),
  name: "Reconhecimento Facial - Lista",
});
routes.push({
  path: "/recognize/:userfaceid",
  component: () => import("./pages/User/Recognize/Recognize.vue"),
  name: "Reconhecimento Facial",
});
routes.push({
  path: "/photographer",
  component: () => import("./pages/Photographer/Dashboard.vue"),
  name: "admin.photographer.dashboard",
  meta: {
    publicName: "Fotógrafo - Dashboard",
    levels: [1, 2],
  },
});
routes.push({
  path: "/photographer/graduations",
  component: () => import("./pages/Photographer/Dashboard.vue"),
  name: "admin.photographer.graduations",
  meta: {
    publicName: "Fotógrafo - Turmas",
    levels: [1, 2],
  },
});

routes.push({
  path: "/admin",
  component: () => import("./pages/Admin/Dashboard.vue"),
  name: "admin.index",
  meta: {
    publicName: "Painel Administrador",
    levels: [1, 2, 3],
  },
});
routes.push({
  path: "/admin/users/:graduationId?",
  component: () => import("./pages/Admin/Users/List.vue"),
  name: "admin.users",
  meta: {
    publicName: "Usuários",
    levels: [1, 2],
  },
});
routes.push({
  path: "/admin/users/create/:graduationId?",
  component: () => import("./pages/Admin/Users/Form.vue"),
  name: "admin.users.create",
  meta: {
    publicName: "Usuários | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/users/edit/:id/:graduationId?",
  component: () => import("./pages/Admin/Users/Form.vue"),
  name: "admin.users.edit",
  meta: {
    publicName: "Usuários | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/users/:userId/downloads-links",
  component: () => import("./pages/Admin/Users/DownloadsLinksList.vue"),
  name: "admin.users.downloadslinks",
  meta: {
    publicName: "Usuários | Links de Downloads",
    levels: [2],
  },
});
routes.push({
  path: "/admin/sac",
  component: () => import("./pages/Admin/Sac/List.vue"),
  name: "admin.sac",
  meta: {
    publicName: "SAC",
    levels: [2],
  },
});
routes.push({
  path: "/admin/sac/new",
  component: () => import("./pages/Admin/Sac/Form.vue"),
  name: "admin.sac.create",
  meta: {
    publicName: "SAC | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/sac/:id",
  component: () => import("./pages/Admin/Sac/Form.vue"),
  name: "admin.sac.edit",
  meta: {
    publicName: "SAC | Editar",
    levels: [2],
  },
});
routes.push({
  name: "graduations.index",
  path: "/admin/graduations",
  component: () => import("./pages/Admin/Graduations/List.vue"),
  meta: {
    publicName: "Turmas",
    levels: [1, 2],
  },
});
routes.push({
  name: "admin.aws.faces.index",
  path: "/admin/aws-faces",
  component: () => import("./pages/Admin/AWSFaces/List.vue"),
  meta: {
    publicName: "Faces AWS",
    levels: [2],
  },
});
routes.push({
  name: "graduations.create",
  path: "/admin/graduations/create",
  component: () => import("./pages/Admin/Graduations/Form.vue"),
  meta: {
    publicName: "Turmas | Criar",
    levels: [1, 2],
  },
});
routes.push({
  name: "graduations.edit",
  path: "/admin/graduations/:id/edit",
  component: () => import("./pages/Admin/Graduations/Form.vue"),
  meta: {
    publicName: "Turmas | Editar",
    levels: [1, 2],
  },
});
routes.push({
  name: "graduations.unrecognized.users",
  path: "/admin/graduations/:id/pictures/unrecognized",
  component: () =>
    import("./pages/Admin/Graduations/UnrecognizedPictures/List.vue"),
  meta: {
    publicName: "Turmas | Fotos não reconhecidas",
    levels: [1, 2],
  },
});
routes.push({
  name: "events.index",
  path: "/admin/graduations/:graduationId/events",
  component: () => import("./pages/Admin/Events/List.vue"),
  meta: {
    publicName: "Eventos | Lista",
    levels: [1, 2],
  },
});
routes.push({
  name: "events.create",
  path: "/admin/graduations/:graduationId/events/create",
  component: () => import("./pages/Admin/Events/Form.vue"),
  meta: {
    publicName: "Eventos | Criar",
    levels: [1, 2],
  },
});
routes.push({
  name: "events.edit",
  path: "/admin/graduations/:graduationId/events/:id/edit",
  component: () => import("./pages/Admin/Events/Form.vue"),
  meta: {
    publicName: "Eventos | Editar",
    levels: [1, 2],
  },
});
routes.push({
  name: "events.galleries",
  path: "/admin/graduations/:graduationId/events/:id/galleries",
  component: () => import("./pages/Admin/Galleries/List.vue"),
  meta: {
    publicName: "Eventos | Galerias",
    levels: [1, 2],
    viewMode: "event",
  },
});
routes.push({
  name: "galleries.index",
  path: "/admin/galleries",
  component: () => import("./pages/Admin/Galleries/List.vue"),
  meta: {
    publicName: "Galerias | Lista",
    levels: [1, 2],
  },
});
routes.push({
  name: "galleries.create",
  path: "/admin/galleries/:graduationId/:eventId/new",
  component: () => import("./pages/Admin/Galleries/Form.vue"),
  meta: {
    publicName: "Galerias | Criar",
    levels: [1, 2],
  },
});
routes.push({
  name: "galleries.edit",
  path: "/admin/galleries/:graduationId/:eventId/:id",
  component: () => import("./pages/Admin/Galleries/Form.vue"),
  meta: {
    publicName: "Galerias | Editar",
    levels: [1, 2],
  },
});

routes.push({
  path: "/admin/galleries/:graduationId/:eventId/:id/select-import-method",
  component: () => import("./pages/Admin/PhotoImport/Index.vue"),
  name: "galleries.pre.import",
  meta: {
    publicName: "Selecione o meio Importação",
    levels: [1, 2],
  },
});

routes.push({
  path: "/google-drive-post-login",
  beforeEnter: (to, from, next) => {
    let parameters = {};
    if (to.query?.access_token) {
      parameters = to.query;
    } else {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      const accessToken = params.get("access_token");
      if (accessToken) {
        for (const [key, value] of params.entries()) {
          parameters[key] = value;
        }
      }
    }

    const code = parameters['code'];

    if (!code) {
      setTimeout(() => {
        alert("Erro ao fazer login no Google: Não autorizado");
      }, 650);
      return next('/');
    }

    let subParams = parameters['state'];

    if (!subParams) {
      setTimeout(() => {
        alert("Erro ao fazer login no Google: Falta total de parâmetros");
      }, 650);
      return next('/');
    }

    subParams = JSON.parse(subParams);

    const galleryId = subParams['galleryId'];
    const eventId = subParams['eventId'];
    const graduationId = subParams['graduationId'];

    if (!galleryId || !eventId || !graduationId) {
      setTimeout(() => {
        alert("Erro ao fazer login no Google: Falta parcial de parâmetros");
      }, 650);
      return next('/');
    }

    return next({
      name: 'google.drive.import',
      params: {
        id: galleryId,
        eventId,
        graduationId,
      },
      query: {
        code,
      }
    })
  }
});

routes.push({
  path: "/admin/galleries/:graduationId/:eventId/:id/:code/import/:parentId?",
  component: () => import("./pages/Admin/PhotoImport/GoogleDrive/Index.vue"),
  name: "google.drive.import",
  meta: {
    publicName: "Selecione as fotos",
    levels: [1, 2],
  },
});

routes.push({
  name: "galleries.pictures",
  path: "/admin/galleries-pictureslist/:mode/:id?",
  component: () => import("./pages/Admin/Galleries/PicturesListComponent.vue"),
  meta: {
    publicName: "Fotos | Lista",
    levels: [1, 2, 3],
  },
});
routes.push({
  name: "services.index",
  path: "/admin/graduations/:graduationId/services",
  component: () => import("./pages/Admin/Services/List.vue"),
  meta: {
    publicName: "Serviços | Lista",
    levels: [2],
  },
});
routes.push({
  name: "services.create",
  path: "/admin/graduations/:graduationId/services/create",
  component: () => import("./pages/Admin/Services/Form.vue"),
  meta: {
    publicName: "Serviços | Criar",
    levels: [2],
  },
});
routes.push({
  name: "services.edit",
  path: "/admin/graduations/:graduationId/services/:id/edit",
  component: () => import("./pages/Admin/Services/Form.vue"),
  meta: {
    publicName: "Serviços | Editar",
    levels: [2],
  },
});
routes.push({
  name: "lots.index",
  path: "/admin/graduations/:graduationId/:serviceId/lots",
  component: () => import("./pages/Admin/Lotes/List.vue"),
  meta: {
    publicName: "Lotes | Lista",
    levels: [2],
  },
});
routes.push({
  name: "lots.create",
  path: "/admin/graduations/:graduationId/:serviceId/lots/create",
  component: () => import("./pages/Admin/Lotes/Form.vue"),
  meta: {
    publicName: "Lotes | Criar",
    levels: [2],
  },
});
routes.push({
  name: "lots.edit",
  path: "/admin/graduations/:graduationId/:serviceId/lots/:id/edit",
  component: () => import("./pages/Admin/Lotes/Form.vue"),
  meta: {
    publicName: "Lotes | Editar",
    levels: [2],
  },
});

routes.push({
  name: "cashback.index",
  path: "/admin/graduations/:graduationId/cashback",
  component: () => import("./pages/Admin/ProgressiveCashbacks/List.vue"),
  meta: {
    publicName: "Cashback Progressivo | Lista",
    levels: [2],
  },
});
routes.push({
  name: "cashback.create",
  path: "/admin/graduations/:graduationId/cashback/create",
  component: () => import("./pages/Admin/ProgressiveCashbacks/Form.vue"),
  meta: {
    publicName: "Cashback Progressivo | Criar",
    levels: [2],
  },
});
routes.push({
  name: "cashback.edit",
  path: "/admin/graduations/:graduationId/cashback/:id/edit",
  component: () => import("./pages/Admin/ProgressiveCashbacks/Form.vue"),
  meta: {
    publicName: "Cashback Progressivo | Editar",
    levels: [2],
  },
});
routes.push({
  name: "cashback.status",
  path: "/admin/graduations/:graduationId/cashback/status",
  component: () => import("./pages/Admin/ProgressiveCashbacks/Show.vue"),
  meta: {
    publicName: "Cashback Progressivo | Status",
    levels: [2],
  },
});

routes.push({
  path: "/admin/gateways",
  component: () => import("./pages/Admin/Gateways/List.vue"),
  name: "admin.gateways",
  meta: {
    publicName: "Formas de Pgto",
    levels: [2],
  },
});
routes.push({
  path: "/admin/gateways/new",
  component: () => import("./pages/Admin/Gateways/Form.vue"),
  name: "admin.gateways.create",
  meta: {
    publicName: "Formas de Pgto |Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/gateways/:id",
  component: () => import("./pages/Admin/Gateways/Form.vue"),
  name: "admin.gateways.edit",
  meta: {
    publicName: "Formas de Pgto | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/financial_categories",
  component: () => import("./pages/Admin/FinancialCategories/List.vue"),
  name: "admin.financial.categories",
  meta: {
    publicName: "Categorias Financeiras",
    levels: [2],
  },
});
routes.push({
  path: "/admin/financial_categories/new",
  component: () => import("./pages/Admin/FinancialCategories/Form.vue"),
  name: "admin.financial.categories.new",
  meta: {
    publicName: "Categorias Financeiras | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/financial_categories/:id",
  component: () => import("./pages/Admin/FinancialCategories/Form.vue"),
  name: "admin.financial.categories.edit",
  meta: {
    publicName: "Categorias Financeiras | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/coupons",
  component: () => import("./pages/Admin/Coupons/List.vue"),
  name: "admin.coupons",
  meta: {
    publicName: "Cupons",
    levels: [2],
  },
});
routes.push({
  path: "/admin/coupons/new",
  component: () => import("./pages/Admin/Coupons/Form.vue"),
  name: "admin.coupons.create",
  meta: {
    publicName: "Cupons | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/coupons/:id",
  component: () => import("./pages/Admin/Coupons/Form.vue"),
  name: "admin.coupons.edit",
  meta: {
    publicName: "Cupons | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/purchases/:userId?",
  component: () => import("./pages/Admin/Purchases/List.vue"),
  name: "admin.purchases",
  meta: (route) => ({
    publicName: route?.params?.userId
      ? "Compras de Usuário"
      : "Compras (geral)",
    levels: [2],
  }),
});
routes.push({
  path: "/admin/purchases/details/:id",
  component: () => import("./pages/Admin/Purchases/PurchaseDetails.vue"),
  name: "admin.purchases.details",
  meta: {
    publicName: "Detalhes da Compra",
    levels: [2],
  },
});
routes.push({
  path: "/admin/payments",
  component: () => import("./pages/Admin/Payments/List.vue"),
  name: "admin.payments",
  meta: {
    publicName: "Pagamentos (geral)",
    levels: [1, 2],
  },
});
routes.push({
  path: "/admin/contacts",
  component: () => import("./pages/Admin/Contacts/List.vue"),
  name: "admin.contacts",
  meta: {
    publicName: "Contatos",
    levels: [1, 2],
  },
});
routes.push({
  path: "/admin/instagram",
  component: () => import("./pages/Admin/Instagram/List.vue"),
  name: "admin.instagram",
  meta: {
    publicName: "Instagram",
    levels: [2],
  },
});
routes.push({
  path: "/admin/instagram/new",
  component: () => import("./pages/Admin/Instagram/Form.vue"),
  name: "admin.instagram.create",
  meta: {
    publicName: "Instagram | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/instagram/:id",
  component: () => import("./pages/Admin/Instagram/Form.vue"),
  name: "admin.instagram.edit",
  meta: {
    publicName: "Instagram | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/reports",
  component: () => import("./pages/Admin/Reports/List.vue"),
  name: "admin.reports",
  meta: {
    publicName: "Relatórios",
    levels: [1, 2],
  },
});
routes.push({
  path: "/admin/config",
  component: () => import("./pages/Admin/Config/List.vue"),
  name: "admin.config",
  meta: {
    publicName: "Configurações",
    levels: [1, 2],
  },
});
routes.push({
  path: "/admin/tutorials",
  component: () => import("./pages/Admin/Tutorial/List.vue"),
  name: "admin.tutorials",
  meta: {
    publicName: "Tutoriais",
    levels: [2],
  },
});
routes.push({
  path: "/admin/tutorials/new",
  component: () => import("./pages/Admin/Tutorial/Form.vue"),
  name: "admin.tutorials.create",
  meta: {
    publicName: "Tutoriais | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/tutorials/:id",
  component: () => import("./pages/Admin/Tutorial/Form.vue"),
  name: "admin.tutorials.edit",
  meta: {
    publicName: "Tutoriais | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/pushs",
  component: () => import("./pages/Admin/Push/List.vue"),
  name: "admin.pushs",
  meta: {
    publicName: "Notificações",
    levels: [2],
  },
});
routes.push({
  path: "/admin/pushs/new",
  component: () => import("./pages/Admin/Push/Form.vue"),
  name: "admin.pushs.create",
  meta: {
    publicName: "Notificações | Criar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/downloads-registry",
  component: () => import("./pages/Admin/DownloadRegistry/List.vue"),
  name: "admin.download.history",
  meta: {
    publicName: "Registro Downloads",
    levels: [1, 2],
  },
});
routes.push({
  path: "/admin/check-ia",
  component: () => import("./pages/CheckIA.vue"),
  name: "admin.check.ia",
  meta: {
    publicName: "Verificador de IA",
    levels: [1, 2, 3],
  },
});

routes.push({
  path: "/tutorial/*",
  component: () => import("./pages/Tutorial.vue"),
  name: "admin.tutorial.general",
  meta: {
    publicName: "Tutorial (geral)",
  },
});
routes.push({
  path: "/image/editor",
  component: () => import("./pages/User/Gallery/OnlineImageEditor.vue"),
  name: "image.editor",
  meta: {
    publicName: "Editor de Imagens",
  },
});
routes.push({
  path: "/seller",
  component: () => import("./pages/Seller/Dashboard.vue"),
  name: "admin.seller.panel",
  meta: {
    publicName: "Painel Vendedor",
    levels: [1, 2, 3],
  },
});
routes.push({
  path: "/seller/sales",
  component: () => import("./pages/Seller/Sales/List.vue"),
  name: "admin.seller.sales",
  meta: {
    publicName: "Vendas",
    levels: [1, 2, 3],
  },
});
routes.push({
  path: "/seller/sales/new",
  component: () => import("./pages/Seller/Sales/Form.vue"),
  name: "admin.seller.sales.create",
  meta: {
    publicName: "Nova Venda",
    levels: [1, 2, 3],
  },
});
routes.push({
  path: "/seller/sales/edit/:id",
  component: () => import("./pages/Seller/Sales/Form.vue"),
  name: "admin.seller.sales.edit",
  meta: {
    publicName: "Editar Venda",
    levels: [1, 2, 3],
  },
});
routes.push({
  path: "/seller/photos-demo",
  component: () => import("./pages/Seller/Sales/PhotosDemo.vue"),
  name: "admin.seller.photos.demo",
  meta: {
    publicName: "Demonstrativo de Fotos",
    levels: [1, 2, 3],
  },
});
routes.push({
  path: "/admin/users/sellerconfig/:id",
  component: () => import("./pages/Admin/Users/SellerConfigForm.vue"),
  name: "admin.seller.profile",
  meta: {
    publicName: "Perfil Vendedor",
    levels: [1, 2],
  },
});

routes.push({
  path: "/purchase/:code",
  component: () => import("./pages/User/Purchases/FastPurchase.vue"),
  name: "activesale.finish",
  meta: {
    publicName: "Finalização de Compra",
  },
});

const router = new Router({
  history: true,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

async function getUser() {
  await axios
    .get("/auth/profile")
    .then((r) => {
      store.commit("setUser", r.data);
    })
    .catch((_) => {
      localStorage.removeItem("token_app");
      store.commit("setUser", null);
    });
}

function afterEach(to) {
  setTimeout(() => {
    store.commit("setLoading", false);
  }, 500);
  Vue.nextTick(() => {
    const meta = typeof to.meta === "function" ? to.meta(to) : to.meta;
    document.title = `UOON® | ${meta?.publicName ?? to.name}` || "UOON®";
  });
}

function resetAxiosConfig() {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token_app")}`,
  };
}

router.beforeEach(async (to, from, next) => {
  console.log({ to })
  // if(to.beforeEnter) {
  //   return to.beforeEnter(to, from, next);
  // }
  store.commit("setLoading", true);

  if (to.path.includes("loginforced")) {
    let token = to.path.replace("/loginforced/", "");
    localStorage.setItem("token_app", token);
    resetAxiosConfig();
    await getUser();
    if (from.path == "/") afterEach(to);
    return next("/");
  }

  const meta = typeof to?.meta === "function" ? to?.meta(to) : to?.meta;
  if (to.path == "/") {
    await getUser();
  } else if (meta?.levels) {
    let user = store.state.user;
    if (!user) {
      await getUser();
      user = store.state.user;
    }
    if (!user || !meta?.levels?.includes(user?.level)) {
      if (from.path == "/") afterEach(to);
      return next(from.path);
    }
  } else if (!store.state?.user) {
    /**
     * Apesar da rota não requerer o usuário, pegá-lo, para caso entrando diretamente em uma rota não autenticada.
     * Isso acontece pois outros itens dependem do usuário logado, como o menu e o header.
     * Entretanto, não há a necessidade de dar um await nesta função, pois pelo fato da rota ser não autenticada, o processo de obtenção de usuário passa a ser em segundo plano.
     */
    getUser();
  }

  // otherwise allow user to access route, and check if logged and update user data if true.
  return next();
});
router.afterEach((to, from) => {
  afterEach(to);
});
export default router;
